import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Image,
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";
import { useTranslation } from "react-i18next";
import i18n from "./i18n"
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t }= useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/w-qcg/_functions", // Replace with your API base URL
      applicationID: "e2758126-8b46-4e9e-aded-b564df9091d8", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, (value: boolean) => {
      setIsUpgraded(value);
      setIsUpgradeBannerOpen(!value);
    });
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = basePlatform.getUpgradeUrl();

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {t('upgrade-text')}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('qr-code-title')}
                content={t('qr-code-content')}
                actions={
                  <Box gap="SP2">
                    <Button
                      suffixIcon={<Icons.Edit />}
                      as="a"
                      href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                      target="_blank"
                    >
                     {t('qr-edit-button')}
                    </Button>
                    <Button
                      suffixIcon={<Icons.PremiumFilled />}
                      skin={isUpgraded ? "premium-light" : "premium"}
                      as="a"
                      href={upgradeUrl}
                      target="_blank"
                    >
                      {isUpgraded ? t('qr-manage-button') : t('qr-upgrade-button')}
                    </Button>
                    <Button
                      suffixIcon={<Icons.AI />}
                      skin="ai"
                      as="a"
                      href={`https://app.qr-gpt.certifiedcode.us/start/${
                        // pass all url params on this page
                        window.location.search
                      }`}
                      target="_blank"
                    >
                       {t('qr-create-qr-button')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
